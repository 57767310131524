// Webpack Imports
import * as bootstrap from "bootstrap";
import "slick-carousel";
import "masonry-layout";
import $ from "jquery"; // Import jQuery explicitly if needed

(function () {
    ("use strict");

    // Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
    var popoverTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl, {
            trigger: "focus",
        });
    });

    // Ensure jQuery is used
    $("#toggle").on("click", function () {
        $(this).toggleClass("active");
        $("#mainNav").toggleClass("open");
        $(".navbar-toggler").toggleClass("open");
        $("body").toggleClass("modal-open");
    });

    $(function () {
        $(".events-carousel").slick({
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: true,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        // centerMode: true,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 800,
                    settings: {
                        arrows: true,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: false,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        infinite: true,
                        autoplay: true,
                        autoplaySpeed: 5000,
                    },
                },
            ],
        });
    });

    // masonry grid
    const cards = document.querySelectorAll(".event-card");
    Array.prototype.forEach.call(cards, (card) => {
        let down,
            up,
            link = card.querySelector("h3 a");
        card.style.cursor = "pointer";
        card.onmousedown = () => (down = +new Date());
        card.onmouseup = () => {
            up = +new Date();
            if (up - down < 200) {
                link.click();
            }
        };
    });
})();